
@import "~font-awesome/css/font-awesome.css";
@import "font-awesome/css/font-awesome.min.css";

.navbar {
  position:fixed;
  top:0;
  left:0;
  min-height: 80px;
  max-height: 80px;
  background:  #fff;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: 'row';
  z-index: 2000;
  width:100%;
}

.nav-area {
  min-width:100%;
  background:transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-logo-box {
  display:flex;
  align-items: flex-start;
  justify-content: center;
  margin-left:5%;
  flex:12;
  min-height: 80px;
  max-height: 80px;
  flex-direction: column;
}

.navbar-logo-caption-div {
  display: flex;
  padding-top: 2px;
  padding-left: 2px;
}

.navbar-logo-caption-span {
                 
                  font-size: 8px;
                  font-style: italic;
}
  
.navbar-logo {
    height:45px;
    width:150px; 
}

.nav-items {
  display: flex;
  flex:88;
  list-style: none;
  text-align: center;
  margin-left: 5%;
  justify-content: flex-start;
  min-height: 80px;
  max-height: 80px;
}

.nav-item {
  display: flex;
  align-items: center;
  min-width: 140px;
  max-width:140px;
  color: #000;
}

  .nav-links {
    color: #111;
    text-decoration: none;
    padding: 0.5rem 1rem;

  }

  .nav-links :hover{
    color: #111;
    text-decoration: none;
    padding: 0.5rem 1rem;
    font-weight: 600;
  }
  
  .nav-item a {
    text-decoration: none;
    font-size: 18px;
    color: #111;
    font-weight: 400;
    margin-right: 2rem;
    padding: 4px 4px;
  }
  
  .nav-item a:hover {
    background: #fff;
    font-weight: 600;
  }

  .menu-icon {
    display: none;
   
  }

  .fa-bars {
    color: #000;
  }


  @media screen and (min-width: 1281px) and (max-width: 1460px) {
    .navbar { 
      min-height: 65px;
      max-height: 65px;
    }

    .nav-items {
      min-height: 65px;
      max-height: 65px;
    }
    .navbar-logo-box {
      min-height: 65px;
      max-height: 65px;
    }

    .navbar-logo {
      height:40px;
      width:130px; 
    }

    .nav-item {
      min-width: 140px;
      max-width: 140px;
    }

    .nav-item a {
     font-size: 16px;
     margin-right: 1rem;
    }
  }
  
  @media screen and (min-width: 1025px) and (max-width: 1280px) {
    .navbar { 
      min-height: 60px;
      max-height: 60px;
    } 
    .nav-items {
      min-height: 60px;
      max-height: 60px;
    }
    .navbar-logo-box {
      min-height: 60px;
      max-height: 60px;
    }
    .navbar-logo {
      height:35px;
      width:110px; 
    }

    .nav-item {
      min-width: 120px;
      max-width: 120px;
    }

    .nav-item a {
      font-size: 14px;
      margin-right: 1rem;
     }
  }
  
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .navbar { 
      min-height: 55px;
      max-height: 55px;
    }
    .nav-items {
      min-height: 55px;
      max-height: 55px;
    }
    .navbar-logo-box {
      min-height: 55px;
      max-height: 55px;
    }
    .navbar-logo {
      height:30px;
      width:100px; 
    }
    .nav-item {
      min-width: 100px;
      max-width: 100px;
    }
    .nav-item a {
      font-size: 12px;
      margin-right: 1rem;
     }

     
  }
  
