*{
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

@font-face {
    font-family: 'Graphik Light';
    src: local('Graphik-Light-Web'), url(../../Fonts/Graphik-Light-Web.woff2) format('woff2');
}

@font-face {
    font-family: 'Graphik Regular';
    src: local('Graphik-Regular-Web'), url(../../Fonts/Graphik-Regular-Web.woff2) format('woff2');
}

.careers-container {
    height: 200px;
    width: 100%;
    background-image: url(../../Images/team.png);
    background-size: 100% 100%;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
}

.careers-box {
    height: auto;
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 80%;
    align-items: center;
    justify-content: center;
    margin-left: 10%;
    margin-right: 10%;
    position: relative;
    box-shadow: 0 10px 16px #0000001a;
    border-radius: 15px;
    padding: 2rem;
    padding-top: 0;
    background-color: white;
    z-index: 0;
}

.careers-box-banner {
    display: flex;
    position: relative;
    top: -64px;
    z-index: 5;
    background-color: #feffff;
    box-shadow: 0 10px 16px #0000001a;
    border-radius: 15px;
}

.careers-service-box {
    height: 80px;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
    margin-left: 10%;
    margin-right: 10%;
}

.careers-service-box p {
    font-family: "Graphik Regular","Arial",Sans-Serif;
    color: #222;
    font-weight: 400;
    font-size: 1.5vw;
    line-height: 1.5;
}


.careers-box-h1 {
    text-transform: uppercase;
    color:#222;
	font-family: "Graphik Regular","Arial",Sans-Serif;
    font-size: 33px;
    padding:0.5rem;
    top: -64px;
}
.careers-box-p {
    font-family: "Graphik Regular","Arial",Sans-Serif;
    color: #222;
    font-weight: 400;
    font-size: 21px;
    line-height: 1.5;
}

@media screen and (min-width: 1281px) and (max-width: 1460px) {
    .careers-container {
        height: 250px;
    margin-bottom: 50px;
    }
    .our-journey-container {
        margin-top: 50px;
    }
    .careers-box-banner {
        top:-20px;
    }
    .careers-box-h1 {
        font-size:22px; 
    }

    .careers-box-p {
        font-size: 16px;
    }
  }
  
  @media screen and (min-width: 1025px) and (max-width: 1280px) {
    .careers-container {
        height: 200px;   
    margin-bottom: 50px;
    }
    .our-journey-container {
        margin-top: 50px;
    }
    .careers-box-banner {
        top:-20px;
    }
    .careers-box-h1 {
        font-size:20px; 
    }

    .careers-box-p {
        font-size: 14px;
    }
  }
  
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .careers-container {
        height: 200px;
        margin-bottom: 50px;
    }
    .careers-box-banner {
        top:-20px;
    }
    .careers-box-h1 {
        font-size:16px; 
    }

    .careers-box-p {
        font-size: 14px;
    }
  
    
  }

