*{
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.whyuscontainer1{
	display: flex;
	flex-direction: column;
	align-items: center;
    width: 50%;
    height:70%;
}

.whyuscontainer2{
	display: flex;
	flex-direction: row;
    width: 100%;
    border-style: solid;
    border-width: 1px;
    border-color: black;
}

.whyuscontainer3{
    min-width: 40%;
    border-style: solid;
    border-width: 1px;
    border-color: #feefde;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #feefde;
}

.whyuscontainer4{
    min-width: 60%;
    border-style: solid;
    border-width: 1px;
    border-color: #feefde;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.whyusicons{
    max-width: 60px;
    max-height: 60px;
    align-self: center;
}

.whyuscontainer5{
    text-align: center;
    color: #fff;
    font-size: 30px;
}


@media screen and (min-width: 1281px) and (max-width: 1460px) {
	.whyuscontainer5{
        font-size: 24px;
    }
  }
  
  @media screen and (min-width: 1025px) and (max-width: 1280px) {
	.whyuscontainer5{
        font-size: 20px;
    }
  }
  
  @media screen and (min-width: 768px) and (max-width: 1024px) {
	.whyuscontainer5{
        font-size: 18px;
    }
	
  }
