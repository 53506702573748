*{
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}


@font-face {
    font-family: 'Graphik Light';
    src: local('Graphik-Light-Web'), url(../../Fonts/Graphik-Light-Web.woff2) format('woff2');
  }
  @font-face {
    font-family: 'Graphik Regular';
    src: local('Graphik-Regular-Web'), url(../../Fonts/Graphik-Regular-Web.woff2) format('woff2');
}

.partners-root{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items:center ;
    background-color: #fff;
    flex:100;
}

.partnercontainerdiv{
	display: flex;
    flex-direction: column;
    align-items: center;
	justify-content: flex-start;
    height:150px;
	width: 90%;
    margin-bottom: 1rem;
}

.partnercontainerdiv h2 {
    color: #222;
    font-size: 28px;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 1rem;
    letter-spacing: 0.2;
    font-family: "Graphik Light","Arial",Sans-Serif;
    align-self: center;
    margin-left: 10%;
    margin-right: 10%;
    text-align: center;
    line-height: 1.8!important;
}

.partnercontainerdiv h3 {
    color: #222;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0.2;
    margin: 0 0 1rem;
    text-transform: uppercase;
    line-height: 1.8!important;
    text-transform: capitalize;
    font-family: "Graphik Regular","Arial",Sans-Serif;
    text-align: center;
}

.partners-container1{
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: flex-start;
    margin-bottom: 60px;
    min-height: 600px;
    max-height: 600px;
}

.partners-container-static{
    background-color:#292424ef;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 25%;
    min-width: 25%;
}

.partners-container-swiper{
    background-color:transparent;
    max-width: 70%;
    min-width: 70%;
}

.partners-swiper-static-container{
    height: 70%;
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
}

.partners-swiper-static-container-ext{
                width: 0px;
                height: 0px;
                border-left-width: 30px;
                border-right-width: 30px;
                border-bottom-width: 100px;
                border-top-width: 100px;
                border-style: solid;
                background-color: transparent;
                border-left-color: #292424ef;
                border-top-color: transparent;
                border-right-color: transparent;
                border-bottom-color: transparent;
                align-self: center;
}

.partners-swiper-static{
    width: 100%;
    height: 100%;
    text-align: center;
    font-weight: 400;
    font-family: "Graphik Light","Arial",Sans-Serif;
    font-size: 30px;
    color: #F2EFDE;
    line-height: "30px";
}

.partners-swiper-pagination{
    display: flex;
    width: 100px;
    height: 25px;
}

.partnercontainerdiv h2 span{
    color: rgb(12, 27, 75);
    font-size: 40px;
    text-transform: capitalize;
    letter-spacing: 0;
    font-family: "Graphik Light", "Arial", sans-serif !important;
    align-self: center;
    line-height: 3;
    display:flex;
    flex-direction: column;
    align-items:center;
}

@media screen and (min-width: 1281px) and (max-width: 1460px) {
    .partners-root{
        min-height:800px;
    }
    .partnercontainerdiv h2 {
        font-size: 26px;
    }
    .partnercontainerdiv h3 {
        font-size: 28px;
    }

    .partners-container1{
        min-width:1200px;
        max-width:1200px;
    }
  }
  
  @media screen and (min-width: 1025px) and (max-width: 1280px) {
    .partners-root{
        min-height:700px;
    }

    .partnercontainerdiv h2 {
        font-size: 24px;
    }
    .partnercontainerdiv h3 {
        font-size: 26px;
    }

    .partners-container1{
        min-width:1000px;
        max-width:1000px;
    }
  }
  
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .partners-root{
        min-height:600px;
    }

    .partnercontainerdiv h2 {
        font-size: 22px;
    }
    .partnercontainerdiv h3 {
        font-size: 24px;
    }

    .partners-container1{
        min-width:1000px;
        max-width:1000px;
    }

    
  }




@media (max-width: 770px) {
    .partners-root {
        display: flex;
        width: 100%;
        height: 650px;
        min-width: 330px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .partners-container1 {
        display: flex;
        flex-direction: column;
        min-height: 100%;
        max-height: 100%;
        max-width: 700px;
        min-width: 330px;
        margin-left: 10px;
        margin-right: 10px;
        align-self: center;
        justify-content: center;
    }

    .partners-container-static {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 95%;
        min-width: 95%;
        min-height: 200px;
    }

    .partners-container-swiper {
        background-color: yellow;
        max-width: 95%;
        min-width: 95%;
        margin-top: 10px;
    }
}

