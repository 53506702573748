*{
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

@font-face {
    font-family: 'Graphik Light';
    src: local('Graphik-Light-Web'), url(../../Fonts/Graphik-Light-Web.woff2) format('woff2');
}

@font-face {
    font-family: 'Graphik Regular';
    src: local('Graphik-Regular-Web'), url(../../Fonts/Graphik-Regular-Web.woff2) format('woff2');
}

h1 {
  color: white;
  text-align: center;
}

.contactus-container {
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
        justify-content: center;
}

.contactus-banner-img {
	width: 100%; 
	height: 200px;
	 margin-bottom: 40px;
}


.navcontact{
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
    min-height: 500px;
	max-height:500px
}

.navcontacttextdiv{
	display: flex;
	justify-content: center;
	width: 80%;
	min-height: 50px;
	max-height:50px;
}	

.navcontacttextdiv p {
	color: #c65622 ;
	font-size: 33px;
	margin: 0px;
}

.navcontactformfields{
	display: flex;
	width: 80%;
	height:'100%';
	flex-direction: column;
	align-items: flex-start;
}

.navcontactformfield{
	border-radius: 7px;
	width: 90%;
	padding-left: 10px;
	color: #feefde;
	margin-bottom: 10px;
}

.contact-inputbox1{
	border-radius: 7px;
	height: 80%;
	width: 100%;
	border-style: solid;
	border-width: 0.5px;
	padding-left: 10px;
	font-size: 12px;
    min-height: 40px;
	max-height:40px;
	background-color: #fff;
}

.navcontactformfieldquery{
	border-radius: 7px;
	width: 100%;
    min-height: 200px;
	max-height:200px;
	border: 1px;
	padding-left: 10px;
	font-size: 15px;
}

.contact-inputbox2{
	border-radius: 7px;
	min-height: 190px;
	max-height:190px;
	width: 100%;
	border-style:  solid;
	border-width:0.5px;
	font-size: 12px;
	background-color: #fff;
	padding: 0 10px 10px;
	text-align: left;
}

.termsconditions{
    color: #111;
	font-size: 12px;
	margin: 0px;
	padding-left: 5px;
}

input.termsconditionscheckbox {
    width : 11px;
    height : 11px;
	margin-top: 5px;
}

.navcontacttermsconditionsfield{
	border-radius: 7px;
	width: 80%;
	margin-left:10%;
	height: 9%;
	border: 1px;
	padding-left: 10px;
	font-size: 10px;
	align-self: flex-start;
}

.navcontactsubmit{
	display: flex;
	justify-content: center;
    min-height: 40px;
	max-height: 40px;
	width:150px;
}

.contact-submitinput{
	border-radius: 7px;
    min-height: 40px;
	width: 100px;
	border: 1px solid;
	font-size: 19px;
	color: white;
	background-color: #c65622 ;
}



@media screen and (min-width: 1281px) and (max-width: 1460px) {
	.navcontacttextdiv p {
		font-size: 24px;
	}

	.navcontact{
		min-height:500px;
		max-height: 500px;
	}

	.contactus-banner-img {
		height: 200px;
		 margin-bottom: 40px;
	}

	.navcontacttextdiv{
		min-height: 60px;
		max-height:60px;
	}

	.navcontactformfield{
		margin-bottom: 10px;
	}

	.contact-inputbox1{
		border-width: 0.3px;
		padding-left: 10px;
		font-size: 12px;
	}

	.navcontactformfieldquery{
		min-height: 200px;
		max-height:200px;
		padding-left: 10px;
	}
	
	.contact-inputbox2{
		min-height: 190px;
		max-height:190px;
		border-width:0.3px;
		font-size: 12px;
		padding: 0 12px 12px;
	}

	.termsconditions{
		font-size: 12px;
		padding-left: 5px;
	}
	
	input.termsconditionscheckbox {
		width : 11px;
		height : 11px;
		margin-top: 5px;
	}

	.navcontactsubmit{
		min-height: 40px;
		max-height: 40px;
		width:150px;
	}
	
	.contact-submitinput{
		min-height: 40px;
		width: 100px;
		font-size: 19px;
	}
	
  }
  
  @media screen and (min-width: 1025px) and (max-width: 1280px) {
	.navcontacttextdiv p {
		font-size: 24px;
	}

	.navcontact{
		min-height:500px;
		max-height: 500px;
	}

	.contactus-banner-img {
		height: 200px;
		 margin-bottom: 40px;
	}

	.navcontacttextdiv{
		min-height: 60px;
		max-height:60px;
	}

	.navcontactformfield{
		margin-bottom: 10px;
	}

	.contact-inputbox1{
		border-width: 0.3px;
		padding-left: 10px;
		font-size: 12px;
	}

	.navcontactformfieldquery{
		min-height: 200px;
		max-height:200px;
		padding-left: 10px;
	}
	
	.contact-inputbox2{
		min-height: 190px;
		max-height:190px;
		border-width:0.3px;
		font-size: 12px;
		padding: 0 12px 12px;
	}

	.termsconditions{
		font-size: 12px;
		padding-left: 5px;
	}
	
	input.termsconditionscheckbox {
		width : 11px;
		height : 11px;
		margin-top: 5px;
	}

	.navcontactsubmit{
		min-height: 40px;
		max-height: 40px;
		width:150px;
	}
	
	.contact-submitinput{
		min-height: 40px;
		width: 100px;
		font-size: 19px;
	}
	
  }
  
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .navcontacttextdiv p {
		font-size: 24px;
	}

	.navcontact{
		min-height:500px;
		max-height: 500px;
	}

	.contactus-banner-img {
		height: 200px;
		 margin-bottom: 40px;
	}

	.navcontacttextdiv{
		min-height: 60px;
		max-height:60px;
	}

	.navcontactformfield{
		margin-bottom: 10px;
	}

	.contact-inputbox1{
		border-width: 0.3px;
		padding-left: 10px;
		font-size: 12px;
	}

	.navcontactformfieldquery{
		min-height: 200px;
		max-height:200px;
		padding-left: 10px;
	}
	
	.contact-inputbox2{
		min-height: 190px;
		max-height:190px;
		border-width:0.3px;
		font-size: 12px;
		padding: 0 12px 12px;
	}

	.termsconditions{
		font-size: 12px;
		padding-left: 5px;
	}
	
	input.termsconditionscheckbox {
		width : 11px;
		height : 11px;
		margin-top: 5px;
	}

	.navcontactsubmit{
		min-height: 40px;
		max-height: 40px;
		width:150px;
	}
	
	.contact-submitinput{
		min-height: 40px;
		width: 100px;
		font-size: 19px;
	}
	
	
 
  }
