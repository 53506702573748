*{
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

@font-face {
    font-family: 'Graphik Light';
    src: local('Graphik-Light-Web'), url(../../Fonts/Graphik-Light-Web.woff2) format('woff2');
}

@font-face {
    font-family: 'Graphik Regular';
    src: local('Graphik-Regular-Web'), url(../../Fonts/Graphik-Regular-Web.woff2) format('woff2');
}

h1 {
  color: white;
  text-align: center;
}

.techimagerow{
    flex: 25;
    display: flex;
    flex-direction: row;
    margin-top: 1%;
    align-items: center;
    justify-content: center;
}

.techimage{
    margin-left: 3%; height: 50%; width: 50%;object-fit: contain;
}

.testing-service-container {
    position: relative; 
    top: 80px;
}

.banner-box-container-1 {
    background-color: rgb(238, 237, 236);
    width: 100%;
	min-height: 240px;
    max-height:240px;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 5;
}

.banner-box-container-2 {
    width: 90%;
	height:100%;
    display:flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    z-index: 6;
	opacity:1
}

.banner-box-img {
		height:auto;
		width: 100%;
		opacity: 1;
}

.banner-box-container-2 h2 {
    color: #222;
    font-size: 22px;
    text-transform: capitalize;
    font-family: "Graphik Regular","Arial",Sans-Serif;
    align-self: flex-start;
    text-transform: none;
    font-weight: 600;
    letter-spacing: 0.2;
    line-height: 1.2!important;
    max-width: 90%;
    text-align: left;
	margin-top:2%;
	opacity:1;
	z-index: 10;
}

.banner-box-container-2-h2 {
    color: #222;
    font-size: 24px;
    text-transform: capitalize;
    font-family: "Graphik Regular","Arial",Sans-Serif;
    align-self: flex-start;
    text-transform: none;
    font-weight: 600;
    letter-spacing: 0.2;
    line-height: 1.2!important;
    max-width: 82%;
    text-align: left;
	margin-top:2%;
	opacity:1;
	z-index: 10;
}

.banner-box-btn {
    background-color: #666;
    width: 20%;
    min-height: 50px;
    max-height: 50px;
    opacity: 1;
    border-radius: 10px;
    margin-top: 2%;
    margin-bottom: 2%;
    z-index: 10;
    display: flex;
    align-items: center;
    align-self: flex-start;
    justify-content: center;
    z-index: 10;
}

.banner-box-btn h2{
    font-size: 24px;
    color: #fff;
    font-weight: bold;
    align-self: center;
    font-family: "Graphik Regular","Arial",Sans-Serif;
    letter-spacing: .84px;
    line-height: 1.2!important;  
	opacity:1;
	z-index: 10;
  }

  .banner-box-btn-link {
    display: flex;
    width: 100%;
    justify-content: center;
  }

.banner-box p {
    font-family: "Graphik Regular","Arial",Sans-Serif;
    font-size: 30px;
    text-align: center;
    color: #F2EFDE;
}

.service-content-box {
	background-color: #fff;
    position: relative;
    height: auto;
    padding: 2rem 0;
    width: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.service-content-box h2 {
    color: #222;
    font-size: 2vw;
    text-transform: capitalize;
    font-family: "Graphik Regular","Arial",Sans-Serif;
    align-self: center;
    text-transform: none;
    font-weight: 600;
    letter-spacing: 0.2;
    line-height: 1.8!important;
    max-width: 60%;
    text-align: left;
	opacity:1;
	z-index: 10;
    padding: 1rem;
}

.service-content-box p {
	font-family: "Graphik Regular","Arial",Sans-Serif;
    color: #222;
	font-size: 21px;
    text-transform: capitalize;
    font-family: "Graphik Regular","Arial",Sans-Serif;
    align-self: center;
    text-transform: none;
    font-weight: 500;
    letter-spacing: 0.1;
    line-height: 1.5!important;
    text-align: justify;
}

.service-content-desc {
    align-self: flex-start;
            display: flex;
            flex-direction: row;
            width: 60%;
            justify-content: center;
}
.service-content-right-img {
    justify-content: flex-start;
            display: flex;
            width: 30%;
			height:30%;
			position:relative;
}

.service-content-left-img {
    display: flex;
    align-self: flex-start;
    width: 40%;
    height: auto;
    justify-content: center;
    padding:1vw 1vw;
    margin-left: 2%;
    margin-top:3%;
}

.service-content-left-desc {
    height:auto;
    width:90%;
    display:flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1vw 1vw;
    flex-direction:column;
}

.service-content-right-desc {
    height:auto;
    width:60%;
    display:flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1vw 1vw;
    flex-direction:column;
}

.service-header-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.service-differtiater-list-item {
    text-align: left;
    color: #222;
    font-size: 24px;
    letter-spacing: 0.1; 
    width: auto;
    opacity: 1;
    font-family: "Graphik Regular","Arial",Sans-Serif;
    align-self: flex-start;
    font-weight: 400;
    line-height: 1.2!important;
    max-width: 90%;
    text-align: left;
	margin-top:2%;         
}

.service-differtiater-list-body {
    display:flex;
    flex-direction:column;
}

.service-content-box .service-ul-header-h2 {
    text-align: left;
    color: #222;
    font-size: 24px;
    letter-spacing: 0.1; 
    width: auto;
    opacity: 1;
    display:flex;
    font-family: "Graphik Regular","Arial",Sans-Serif;
    align-self: flex-start;
    font-weight: 600;
    line-height: 1.2!important;
	margin-top:2%;  
    margin-left: 5%;  
}


@media screen and (min-width: 1281px) and (max-width: 1460px) {
    .banner-box-container-1 {
        min-height:200px;
        max-height:200px;
    }
    .testing-service-container {
        top: 60px;
    } 
    .banner-box-container-2 h2 {
        font-size:18px;
    }

    .banner-box-container-2-h2 {
        font-size:20px;
    }

    .banner-box-btn {
        
        min-height:40px;
        max-height: 40px;
    }

    .banner-box-btn h2{
        font-size:20px;
    }

    .service-content-box p {
        font-size: 16px;
    }

    .service-differtiater-list-item {
        font-size: 16px;
    }

    .service-content-box .service-ul-header-h2 {
        font-size: 16px;
    }
  }
  
  @media screen and (min-width: 1025px) and (max-width: 1280px) {
    .banner-box-container-1 {
        min-height:180px;
        max-height:180px;
    }
    .testing-service-container {
        top: 55px;
    } 
    .banner-box-container-2 h2 {
        font-size:16px;
    }

    .banner-box-container-2-h2 {
        font-size:18px
    }

    .banner-box-btn {
        
        min-height:35px;
        max-height: 35px;
    }

    .banner-box-btn h2{
        font-size:18px;
    }

    .service-content-box p {
        font-size: 15px;
    }

    .service-differtiater-list-item {
        font-size: 14px;
    }

    .service-content-box .service-ul-header-h2 {
        font-size: 14px;
    }
  }
  
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .banner-box-container-1 {
        min-height:160px;
        max-height:160px;
    }
    .testing-service-container {
        top: 50px;
    } 

    .banner-box-container-2 h2 {
        font-size:14px;
    }

    .banner-box-container-2-h2 {
        font-size:16px;
    }

    .banner-box-btn {
        
        min-height:25px;
        max-height: 25px;
    }

    .banner-box-btn h2{
        font-size:16px;
    }

    .service-content-box p {
        font-size: 13px;
    }

    .service-differtiater-list-item {
        font-size: 14px;
    }

    .service-content-box .service-ul-header-h2 {
        font-size: 14px;
    }


  }