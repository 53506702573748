.devopsbannerbox {
    opacity:1;
    width: 100%;
    min-height:1000px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 10%;
  }

.bannercontent {
    display:flex;
    opacity: 1;
    width: 50%;
    color: #F2EFDE;
    padding: 3rem;
    font-size: 1.5vw;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }

  .banner-content {
    flex: 100%;
    max-width: 100%;
    align-self: center;
}

.banner-head {
    text-align: left!important;
    color: #F2EFDE;
    font-size: 45px!important;
    font-family: ManifoldCF-Medium;
    letter-spacing: .3px;
    margin: 0;
    width: auto!important;
    align-self:center;
    margin-bottom: 3%;
}

 .banner-p {
    font-family: ManifoldCF;
    font-size: 30px;
    text-align: center;
    width: 40%;
    color: #F2EFDE;
}

.managed-services {

    background: linear-gradient(360deg,#4a4a4e 30%,#965024 100%);
    background-color: #161617;
   
    position: relative;
    overflow: visible;
    height: auto;
    overflow-x: clip;
    padding: 5rem 0;
    width: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.managed-services .banner-p {
    font-family: ManifoldCF;
    font-size: 30px;
    text-align: center;
    width: 50%;
    color: #F2EFDE;
}

.services-card {
    padding-top: 5%;
    display: flex;
    flex-direction:column;
    width:70%;
    height:100%;
    align-items:center;
    justify-content: center;
}

.services-card-row {
    
    display: flex!important;
    flex-direction:row;
    width:100%;
    height:auto;
    align-items:center;
    justify-content: center;

}

.services-card-inner {
   
    background: 0 0;
    padding: 3% 5%!important;
    width: 50%;
    border-radius: 24px!important;
    box-shadow: 0 26px 31px #0000001a;
    background-color: #fff;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
    margin-bottom: 5%;
    margin-left: 5%;
    margin-right: 5%;
    flex:50;
}



.services-card-data p {
    text-align: left!important;
    color: #1f3c66!important;
    font-size: 25px!important;
    font-family: ManifoldCF-Medium;
    letter-spacing: .3px;
    margin: 0;
    width: auto!important;
}

@media (max-width: 1800px) {
    .services-card .services-card-data p {
        font-size: 18px!important;
    }
  }

.services-card-data h4 {
    text-align: left!important;
    color: #1f3c66!important;
    font-size: 30px!important;
    font-family: ManifoldCF-Medium;
    letter-spacing: .3px;
    margin: 0;
    width: auto!important;
}

@media (max-width: 1800px) {
    .services-card .services-card-data h4 {
        font-size: 25px!important;
    }
  }



@media (max-width: 1600px) {
.services-card .services-card-inner {
    min-height: 93%;
}
}

.services-card .services-card-inner .img-services-card {
    position: absolute;
    display: block;
    margin-bottom: 20px;
    max-width: 80px;
    left: -30px;
    top: -30px;
}

.services-card .img-services-card {
    transition: all .8s ease;
}

img {
    vertical-align: middle;
    border-style: none;
}

.services-card .services-card-inner .services-card-data-head {
    display: block;
    margin-bottom: 3%;
    color: #1f3c66;
}

element.style {
    visibility: visible;
    animation-name: fadeInUp;
}

.banner .banner-cont p {
    font-size: 1.1rem;
    color: #fff;
    margin: 0 0 2rem;
    line-height: 2rem;
    font-weight: 400;
}

.tech-stack {
   background-color: rgb(248, 249, 250);;
    position: relative;
    overflow: visible;
    height: auto;
    overflow-x: clip;
    padding: 5rem 0;
    width: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.tech-stack-container {
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 70%;
}

.tech-stack-col {
    width:15%;
    display:flex;
    flex-direction:column;
    margin-left:2%;
    margin-right:2%;
}

.tech-stack-img-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px auto;
    max-height:580px;
}

.tech-stack-box {
    padding: 10px;
    
    color: #fff;
    min-height: 115px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin:3%;
    border-radius:10%;
    background: linear-gradient(37deg,#a05c24 -24%,#083680 100%);
}

.tech-stack-p {
    font-family: ManifoldCF;
    font-size: 25px;
    text-align: center;
    width: 80%;
    color: #F2EFDE;
}

.tech-stack-banner {

    display:flex;
    justify-content:flex-start;
    margin-left:30%;
    align-items:center;
    width:100%;
    height:10%;
}
.tech-stack-head {
    text-align: left!important;
    color: #080f67;
    font-size: 50px;
    font-family: ManifoldCF-Medium;
    letter-spacing: .3px;
    width: auto!important;
    align-self:flex-start;
    margin: 3%;
}

.ovrlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding: 20px 35px;
    z-index: 2;
    -webkit-transition: all 300ms ease-out;
    -moz-transition: all 300ms ease-out;
    -o-transition: all 300ms ease-out;
    -ms-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background: rgba(255,255,255,.18);
    background: -moz-linear-gradient(top,rgba(255,255,255,.22) 0%,rgba(0,0,0,.47) 100%);
    background: -webkit-linear-gradient(top,rgba(255,255,255,.22) 0%,rgba(0,0,0,.47) 100%);
    background: linear-gradient(to bottom,rgba(255,255,255,.22) 0%,rgba(0,0,0,.47) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff',endColorstr='#000000',GradientType=0);
}

.devops-consulting-banner-div-body {
    display:flex;
    flex-direction:column;
    align-items:'center';
    justify-content: 'center';
}

.devops-consulting-banner-head {
    text-align: left!important;
    color: #1f3c66!important;
    font-size: 35px!important;
    font-family: ManifoldCF-Bold;
    letter-spacing: .3px;
    margin: 0;
    padding-bottom:2rem;
    width: auto!important;
    opacity: 1;
    
    padding: 1rem;
              
              
             
              align-self:left;
              
              
}

.devops-consulting-banner-list-item {
    text-align: left!important;
    color: #222!important;
    font-size: 25px!important;
    font-family: ManifoldCF-Bold;
    letter-spacing: .3px;
    margin: 0;
    width: auto!important;
    opacity: 1;
    
    padding: 1rem;
              
}

