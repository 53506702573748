*{
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.wrap {
  box-sizing: border-box;
}
#content {
  box-sizing: border-box;
}

@font-face {
  font-family: 'Graphik Light';
  src: local('Graphik-Light-Web'), url(../../Fonts/Graphik-Light-Web.woff2) format('woff2');
}

.swiper {
    width: 100%;
    height: 100%;
    /* max-height: 70vh;
    min-height: 70vh; */
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;
  height: -webkit-max-content!important;
  height: -moz-max-content!important;
  height: max-content!important;
  max-height:85vh;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide-active {
  width:100%;
  height:100%;
  max-height:85vh;
}

.testimonial-container {
  position: relative;
  width:100%;
  height:100%;
  border-width: 2px;
  border-style: solid;
  border-color:#fff;
  border-radius:2vh;
  margin-left: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction:column;
  opacity:1;
}

.testimonial-section-box {
  min-height:80%;
  max-height:80%;
  min-width:800px;
  max-width:800px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.testimonial-section-clients-box {
  height: 10%;
  min-width: 800px;
  max-width:800px;
  display: flex;
  align-items:center;
  justify-content:center;
  flex-direction:row;
  margin-bottom: 60px;
}

.testimonial-section-inner-box {
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height:90%;
  max-height:90%;
  min-width:90%;
  max-width:90%;
  background: #fff;
  padding: 1vw 1vw;
  box-shadow: 0 6px 13px #0000001a;
  border-radius: 14px;
  position:relative;
  margin-bottom: 60px;
}

.testimonial-section-double-quote {
  position: absolute;
  max-width: 80px;
  left: -2%;
  top: -2%;
  z-index: 2;
}

.clienttextbox {
  flex:70;
  text-align: justify;
  display:flex;
  align-items: center;
  justify-content: center;
  overflow:hidden;
}

.partners-text{
  width: 100%;
  height: 100%;
  text-align: center;
  font-weight: 500;
  font-family: Open Sans,sans-serif;
  font-size: 20px;
  color: #F2EFDE;
  line-height: "25px";
}

.clientlogo {
  display:flex;
  height:80%;
  width:20%;
  background: #fff;
  padding: 2vw 6.5vw;
  box-shadow: 0 6px 13px #0000001a;
  border-radius: 14px;
  align-items: center;
  justify-content: center;
  margin-left:2%;
  margin-right: 2%;
}

.swiper-pagination {
	position: absolute;
	top: 10px;
	right: 10px;
	width: auto !important;
	left: auto !important;
	margin: 0;
}
.swiper-pagination-bullets {
	padding: 5px 10px;
	border-radius: 0;
	width: auto;
	height: 30px;
	text-align: flex-end;
	line-height: 30px;
	font-size: 12px;
	color:#fff;
	opacity: 1;
  bottom:0;
  right:0;
  top: 10px;
	right: 50px;
}
.swiper-pagination-bullet-active {
	color:#fff;
	background: #ff0000;
}

.my-custom-pagination-div {
  display:flex;
  height:100px;
  background-color: red;
}

/* change the size of the bullet and active color */

.swiper-pagination-bullet {
  background-color: blue;
  display: inline-block;
  width: 2rem;
  height: 2rem;
}

/* change color of next 2 bullets in sequence to white*/

.swiper-pagination-bullet-active-next, .swiper-pagination-bullet-active-next-next {
background-color: white;
}

/* change color of previous bullet to white*/

.swiper-pagination-bullet-active-prev {
background-color: white;
}

.testimonial-section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.testimonial-section h2 {
  color: orangered;
  font-size: 24px;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 0.5rem;
  letter-spacing: 0.2;
  font-family: "Graphik Regular","Arial",Sans-Serif;
  align-self: flex-start;
  margin-left: 10%;
  margin-right: 10%;
  text-align: left;
  line-height: 1.8!important;
}

.testimonial-section h4 {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 1rem;
    letter-spacing: 0.2;
    font-family: "Graphik Light","Arial",Sans-Serif;
    align-self: flex-start;
    margin-left: 10%;
    margin-right: 10%;
    text-align: left;
    line-height: 1.8!important;
}

.testimonial-section p {
  color: #222;
  font-size: 20px;
  font-weight: 400;
  text-transform: capitalize;
  margin-bottom: 1rem;
  letter-spacing: 0.2;
  font-family: "Graphik Regular","Arial",Sans-Serif;
  align-self: center;
  margin-left: 10%;
  margin-right: 10%;
  text-align: left;
  line-height: 1.8!important;
}


@media screen and (min-width: 1281px) and (max-width: 1460px) {
  .testimonial-section p {
    font-size:18px;
  }

  .testimonial-section h2 {
    font-size:22px;
  }
  .testimonial-section h4 {
    font-size:16px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
  .testimonial-section p {
    font-size:16px;
  }

  .testimonial-section h2 {
    font-size:20px;
  }
  .testimonial-section h4 {
    font-size:14px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .testimonial-section p {
    font-size:14px;
  }

  .testimonial-section h2 {
    font-size:18px;
  }
  .testimonial-section h4 {
    font-size:12px;
  }

  
}