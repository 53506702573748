*{
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}


@font-face {
    font-family: 'Graphik Light';
    src: local('Graphik-Light-Web'), url(../../Fonts/Graphik-Light-Web.woff2) format('woff2');
}

.aboutus-container {
    height: 400px;
    width: 100%;
    background-image: url(../../Images/aboutus-banner-img.jpg);
    background-size: 100% 100%;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
}

.aboutus-box {
    height: auto;
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 80%;
    align-items: center;
    justify-content: center;
    margin-left: 10%;
    margin-right: 10%;
    position: relative;
    box-shadow: 0 10px 16px #0000001a;
    border-radius: 15px;
    padding: 2rem;
    padding-top: 0;
    background-color: white;
    z-index: 0;
}

.aboutus-box-banner {
    display: flex;
    position: relative;
    top: -64px;
    z-index: 5;
    background-color: #feffff;
    box-shadow: 0 10px 16px #0000001a;
    border-radius: 15px;
}

.button-reachus {
    height:60px;
    width:auto;
    background: linear-gradient(90.35deg,#ce651a 25%,#4e4e51  100%);
    display:flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    padding: 1vw 1.5vw;
    border-radius:25px;
    z-index: 101;
    margin-top:100px;
}

.button-reachus-h2 {
    font-family: "Graphik Regular","Arial",Sans-Serif;
    color: #fff;
    font-weight: 400;
    font-size: 33px;
    line-height: 1.5;
}

.journey-box {
    height:auto;
    width:70%;
    align-items: center;
    justify-content: center;
    display:flex;
    background: #fff;
    padding: 1vw 1vw;
    box-shadow: 0 6px 35px #0000001a;
    border-radius: 14px;
    flex-direction:column;
    position:relative;
}

.leadership-box {
    height:auto;
    width:70%;
    margin-top:50px;
    align-items: center;
    justify-content: center;
    display:flex;
    background: #fff;
    padding: 1vw 1vw;
    box-shadow: 0 6px 35px #0000001a;
    border-radius: 14px;
    flex-direction:column;
    position:relative;
}

.leadership-box p {
font-family: "Graphik Regular","Arial",Sans-Serif;
    color: #222;
    font-weight: 400;
    font-size:18px;
    line-height: 1.5;
}

.journey-box-ext{
    width: 0px;
    height: 0px;
    border-left-width: 20px;
    border-right-width: 20px;
    border-bottom-width: 30px;
    border-top-width: 30px;
    border-style: solid;
    background-color: transparent;
    border-left-color:#fff;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    align-self: center;
    
}

.journey-box-left-ext{
    width: 0px;
    height: 0px;
    border-left-width: 20px;
    border-right-width: 20px;
    border-bottom-width: 30px;
    border-top-width: 30px;
    border-style: solid;
    background-color: transparent;
    border-left-color:transparent;
    border-top-color: transparent;
    border-right-color: #fff;
    border-bottom-color: transparent;
    align-self: center;
    
    
} 
.journey-box p {
    font-family: "Graphik Regular","Arial",Sans-Serif;
    color: #222;
    font-weight: 400;
    font-size:18px;
    line-height: 1.5;
}

.journey-point-left {
    margin-right: -53px;
     align-self: center;
      z-index: 105;
}

.journey-point-right {
    margin-left: -53px;
     align-self: center;
      z-index: 105;
}

.our-journey-container {
    width:100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    justify-content: center;
    background-color: #feffff;
    margin-top: 100px;
}

.journey-container {
   display:flex;
   flex-direction: column;
   height:auto;
   width:100%; 
   position:relative;
   margin-bottom: 100px;
}

.journey-container .journey-container-line {
        position: absolute;
        left: 50%;
        height: 100%;
        border-right-style: dotted;
        border-color: #222;
}

.journey-container-left {
    width:50%;
    
    display:flex;
    justify-content: flex-start;
}
.journey-container-right {
    width:100%;
    display:flex;
    justify-content: flex-end;
}

.journey-container-box {
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    width: 40%;
    margin-left: 10%;
    justify-content: center;
}

.leadership-container {
    display: flex;
    flex-direction: row;
    padding-bottom: 100px;
}

.leadership-img-box {
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    width: 40%;
    margin-left: 0px;
    justify-content: center;
    margin-top: 50px;
    position: relative;
}

.journey-container-line-2 {
    border-top-style:dashed;
    border-top-width: 0.5px;
    border-color: #222;
    width:30%;
    height:1px;
    align-self: center;
    position:relative
}

.journey-container-line-2 .journey-point-on-line {
    position: absolute;
    display: block;
    right: -60px;
    align-self: center;
}



.journey-right-container-box {
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    width: 40%;
    margin-right: 10%;
    justify-content: center;
}

.journey-point-img {
    width: 100px;
    height: 100px;
}

.leadership-tag {
    position: absolute;
    bottom: 20px;
    max-width: 300px;
    max-height: 200px;
    left: 50%;
    background-color: rgba(183, 95, 40, 0.7);
    display:flex;
    align-items:center;
    justify-content: center;
}
.leadership-tag-left {
    position: absolute;
    bottom: 20px;
    max-width: 300px;
    max-height: 200px;
    right: 50%;
    background-color: rgba(183, 95, 40, 0.7);
    display:flex;
    align-items:center;
    justify-content: center;
}

.leadership-tag  h1 { 
    font-family: "Graphik Regular","Arial",Sans-Serif;
    font-weight: 600;
    font-size: 25px;
    color: #fff;
    text-align: left;
    padding:1vw 1.5vw;
  }

  .leadership-tag-left  h1 { 
    font-family: "Graphik Regular","Arial",Sans-Serif;
    font-weight: 600;
    font-size: 25px;
    color: #fff;
    text-align: left;
    padding:1vw 1.5vw;
  }

  .leadership-tag  p { 
    font-family: "Graphik Regular","Arial",Sans-Serif;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    text-align: left;
  }

  .leadership-tag-left  p { 
    font-family: "Graphik Regular","Arial",Sans-Serif;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    text-align: left;
  }

.leadership-img {
    width: 400px;
    height: 500px;
    border-radius: 14px;
}

.client-box {
    height:120px;
    width:270px;
    background: #fff;
    padding: 1vw 3.5vw;
    box-shadow: 0 6px 35px #0000001a;
    border-radius: 14px;
    align-items:center;
    margin-left:5%;
    margin-right:5%;
    margin-top:5%;
}

.aboutus-box-h1 {
    text-transform: uppercase;
    background: transparent -webkit-linear-gradient(37deg,#f06407  0,#cb6313 35%,#ee8a07 50%,#59595a 60%,#454647 70%,#333334 80%,#353536 91%,#312f2f 100%) 0 0 no-repeat padding-box;
    color:#fff;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	-webkit-background-clip: text;
	font-family: "Graphik Regular","Arial",Sans-Serif;
    font-size: 33px;
    padding:0.5rem;
    top: -64px;
}
.aboutus-box-p {
    font-family: "Graphik Regular","Arial",Sans-Serif;
    color: #222;
    font-weight: 400;
    font-size: 21px;
    line-height: 1.5;
}

.client-banner-h2 {
    text-transform: uppercase;
    background: transparent -webkit-linear-gradient(37deg,#f06407  0,#cb6313 35%,#ee8a07 50%,#59595a 60%,#454647 70%,#333334 80%,#353536 91%,#312f2f 100%) 0 0 no-repeat padding-box;
    color:#fff;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	-webkit-background-clip: text;
	font-size: 24px;
	font-family: "Graphik Regular","Arial",Sans-Serif;
}

.mission-container {
    display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 50px;
            width: 53%;
            right: 55%;
            top: 10%;
            background-color:#fff;
            padding: 2.5vw 3vw;
            box-shadow: 0 6px 35px #0000001a;
            border-radius: 14px;
            min-height: 200px;
              max-height:200px;
            align-self: flex-start;
}

.mission-container p{

font-family: Rubik,sans-serif;
              color: #222;
              font-weight: 400;
              font-size: 21px;
              line-height: 1.5;
}

.vision-container {
    position: absolute;
              bottom: 30%;
              left: 47%;
              width: 53%;
              background-color: #d3d3d3;
              padding: 2vw 3vw;
              box-shadow: 0 6px 35px #0000001a;
              border-radius: 14px;
              min-height: 200px;
              max-height:200px;
              display: flex;
              align-items: center;
}

.vision-container p{

    font-family: Rubik,sans-serif;
                  color: #222;
                  font-weight: 400;
                  font-size: 21px;
                  line-height: 1.5;
    }

    .client-container {
        height: auto;
          display: flex;
          flex-direction: column;
          align-self: center;
          background-color: #feffff;
          width: 80%;
          align-items: center;
          justify-content: center;
          margin-left: 10%;
          margin-right: 10%;
          padding-bottom: 100px;
          margin-bottom:100px;
    }
    .client-container-banner {
        display: flex;
            flex-direction: row;
            align-items: center;
            padding:1vw 2vw;
    }

    .client-container-banner-logo {
        align-self: center;
              vertical-align: middle;
              margin-bottom: 3%;
              margin-top: 3%;
              height: 70px;
              width: 70px;
    }

    .partner-banner-img {
        height: 300px;
        width: 100%;
        background-image: url(../../Images/partner-banner.jpg);
        background-size: 100% 100%;
        display: flex;
        align-self: center;
        align-items: center;
        justify-content: center;
    }

    .client-banner-img {
        height: 300px;
        width: 100%;
        background-image: url(../../Images/client-banner.jpg);
        background-size: 100% 100%;
        display: flex;
        align-self: center;
        align-items: center;
        justify-content: center;
    }

.partner-description {
        display: flex;
        align-items: center;
        padding: 16px;
        border-bottom-style: solid;
        border-bottom-width:1px;
        border-bottom-color: #f6f6f6;
}

.partner-description p{
    font-family: "Graphik Regular","Arial",Sans-Serif;
    color: #222;
    font-weight: 400;
    font-size:18px;
    line-height: 1.5; 
}

.partner-section {
    height: 300px;
     display: flex;
      flex-direction: row;
}

.infrastructure-container {
    height: auto;
          display: flex;
          flex-direction: column;
          align-self: center;
          background-color: #feffff;
          width: 80%;
          align-items: center;
          justify-content: center;
          margin-left: 10%;
          margin-right: 10%;
          padding-bottom: 1vw;
          margin-bottom: 50px;
}

.infrastructure-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
    width: 50%;
    margin: 1%;
}

.infrastructure-image {
    align-self: center;
                vertical-align: middle;
                margin-bottom: 3%;
                margin-top: 3%;
                height: 80%;
                width: 80%;
}

.infrastructure-h2 {
    color: #222;
	font-size: 16px;
	font-family: "Graphik Regular","Arial",Sans-Serif;
    align-self:flex-start;
    display:flex;
    text-align: left;
    padding:0px;
    margin-left:10%;
}

.infrastructure-box p{
    font-family: "Graphik Regular","Arial",Sans-Serif;
    color: #222;
    font-weight: 400;
    font-size:14px;
    line-height: 1.5; 
    align-self:flex-start;
    text-align: left;
    margin-left:10%;
}

@media screen and (min-width: 1281px) and (max-width: 1460px) {
    .aboutus-container {
        height: 200px;
    
        
    margin-bottom: 50px;
    }
    .our-journey-container {
        margin-top: 25px;
    }
    .aboutus-box-banner {
        top:-20px;
    }
    .aboutus-box-h1 {
        font-size:20px; 
    }

    .aboutus-box-p {
        font-size: 14px;
    }

    .journey-box p {
        font-size: 12px;
    }

    .leadership-box p {
        font-size: 12px;
    }

    .vision-container p{
        font-size:14px;
    }
    .mission-container p{
        font-size:14px;
    }

    .leadership-img {
        width: 200px;
        height: 300px;
    }

    .journey-point-img {
        width: 60px;
        height: 60px;
    }

    .button-reachus-h2 {
        font-size: 20px;
    }

    .button-reachus {
        height:30px;
        margin-top:50px;
    }

    .journey-point-left {
        margin-right: -32px;
    }

    .journey-point-right {
        margin-left: -28px;
    }
  
  }
  
  @media screen and (min-width: 1025px) and (max-width: 1280px) {
    .aboutus-container {
        height: 200px;
    
        
    margin-bottom: 50px;
    }
    .our-journey-container {
        margin-top: 50px;
    }
    .aboutus-box-banner {
        top:-20px;
    }
    .aboutus-box-h1 {
        font-size:20px; 
    }

    .aboutus-box-p {
        font-size: 14px;
    }

    .journey-box p {
        font-size: 12px;
    }

    .leadership-box p {
        font-size: 12px;
    }

    .vision-container p{
        font-size:14px;
    }
    .mission-container p{
        font-size:14px;
    }

    .leadership-img {
        width: 200px;
        height: 300px;
    }

    .journey-point-img {
        width: 60px;
        height: 60px;
    }

    .button-reachus-h2 {
        font-size: 20px;
    }

    .button-reachus {
        height:30px;
        margin-top:50px;
    }

    .journey-point-left {
        margin-right: -32px;
    }

    .journey-point-right {
        margin-left: -28px;
    }
  
  }
  
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .aboutus-container {
        height: 200px;
    
        
    margin-bottom: 40px;
    }
    .our-journey-container {
        margin-top: 25px;
    }
    .aboutus-box-banner {
        top:-20px;
    }
    .aboutus-box-h1 {
        font-size:20px; 
    }

    .aboutus-box-p {
        font-size: 14px;
    }

    .journey-box p {
        font-size: 12px;
    }

    .leadership-box p {
        font-size: 12px;
    }

    .vision-container p{
        font-size:14px;
    }
    .mission-container p{
        font-size:14px;
    }

    .leadership-img {
        width: 200px;
        height: 300px;
    }

    .journey-point-img {
        width: 60px;
        height: 60px;
    }

    .button-reachus-h2 {
        font-size: 20px;
    }

    .button-reachus {
        height:30px;
        margin-top:50px;
    }

    .journey-point-left {
        margin-right: -32px;
    }

    .journey-point-right {
        margin-left: -28px;
    }

    .journey-container {
        margin-bottom: 50px;
    }

    .leadership-container {
        padding-bottom: 50px;
    }

    .leadership-tag  h1 { 
        
        font-size: 16px;
    }

    .leadership-tag  p { 
        
        font-size: 12px;
    }

    .leadership-tag-left  h1 { 
        
        font-size: 16px;
    }

    .leadership-tag-left  p { 
        
        font-size: 12px;
    }

    .client-container {
        padding-bottom: 50px;
        margin-bottom: 50px;
    }

    .client-container-banner-logo {
        width:25px;
        height:25px;
    }

    .client-banner-h2 {
        font-size: 22px;
    }

    .partner-banner-img {
        
            height: 150px;
    }

    .client-banner-img {
        height: 150px;
    }

    .partner-description p{
        font-size:12px;
    }
    .partner-section {
        height:180px;
    }
  
    
  }

