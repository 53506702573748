*{
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

@font-face {
    font-family: 'Graphik Light';
    src: local('Graphik-Light-Web'), url(../Fonts/Graphik-Light-Web.woff2) format('woff2');
}

@font-face {
    font-family: 'Graphik Regular';
    src: local('Graphik-Regular-Web'), url(../Fonts/Graphik-Regular-Web.woff2) format('woff2');
}

body {
  background-color: transparent;
}

h1 {
  color: white;
  text-align: center;
}

.footer-box {
	display:flex;
	flex-direction: column;
    justify-content: center;
    width: 90%;
	min-height:200px;
	max-height:200px;
}

.footer-box h2 {
	color: #222;
    font-size: 24px;
    text-transform: capitalize;
    margin-bottom: 2rem;
    letter-spacing: 0.2;
    font-family: "Graphik Light","Arial",Sans-Serif;
    align-self: center;
	justify-content: flex-start;
	padding:1px 1.5px;
    text-align: left;
}

.footer-links {
	color: #222;
    font-size: 24px;
	width:30%;
	padding:3%;
    letter-spacing: 0.2;
    font-family: "Graphik Regular","Arial",Sans-Serif;
    align-self: center;
	justify-content: flex-start;
    text-align: left;
	text-underline-position: below;
    text-decoration-line: underline;
}

.footer-policy-links {
	color: #222;
    font-size: 12px;
	width:20%;
	padding-top:2px;
    letter-spacing: 0.2;
    font-family: "Graphik Regular","Arial",Sans-Serif;
    align-self: flex-start;
	justify-content: flex-start;
    text-align: left;
	text-underline-position: below;
    text-decoration-line: underline;
}

.footer-img {
	align-self: center;
    vertical-align: middle;
    margin-bottom: 3%;
    margin-top: 3%;
	margin-right: 3%;
    height: 3.5vw;
    width: 3.5vw;
}

.footercontainer1 {
    width: 100%;
	height:20%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding-top:5%;
	padding-bottom:10%;
}

.footercontainertop {
    width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.end-banner .footercontainertop p{
    font-family: "Graphik Regular","Arial",Sans-Serif;
    font-size: 30px;
    text-align: center;
    color: #FFF;
}

.footercontainer2 {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	width: 100%;
	height:90%;
	border-bottom-style: solid ;
	border-bottom-width:0.5px;
	border-color: #222;
	margin-bottom: 1%;
}

.footercontainer3 {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	height:10%;
}

.end-banner .footercontainer3 span {
	color: #222;
    font-size: 14px;
    letter-spacing: 0.2;
    font-family: "Graphik Regular","Arial",Sans-Serif;
    align-self: flex-start;
	justify-content: flex-end;
	padding:1px 1.5px;
	padding-top:2px;
    text-align: left;
}



@media screen and (min-width: 1281px) and (max-width: 1460px) {
	.end-banner .footercontainertop p{
		font-size:24px;
	}

	.footer-box {
		min-height:180px;
		max-height:180px;
	}

	.footer-box h2 {
		font-size: 14px;
	}

	.footer-links {
		font-size: 14px;
	}

	.end-banner .footercontainer3 span {
		font-size: 10px;
	}

	.footer-policy-links {
		font-size: 10px;
	}
  }
  
  @media screen and (min-width: 1025px) and (max-width: 1280px) {
	.end-banner .footercontainertop p{
		font-size:24px;
	}

	.footer-box {
		min-height:160px;
		max-height:160px;
	}

	.footer-box h2 {
		font-size: 14px;
	}

	.footer-links {
		font-size: 14px;
	}

	.end-banner .footercontainer3 span {
		font-size: 10px;
	}

	.footer-policy-links {
		font-size: 10px;
	}
  }
  
  @media screen and (min-width: 768px) and (max-width: 1024px) {
	.end-banner .footercontainertop p{
		font-size:24px;
	}

	.footer-box {
		min-height:150px;
		max-height:150px;
	}

	.footer-box h2 {
		font-size: 12px;
	}

	.footer-links {
		font-size: 12px;
	}

	.end-banner .footercontainer3 span {
		font-size: 10px;
	}

	.footer-policy-links {
		font-size: 10px;
	}
	
  }



@media (max-width: 700px) {
	.footercontainer1 {
		width: 95%;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}