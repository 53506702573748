*{
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.reachusatdiv{
	min-height: 40px;
    max-height: 40px;
    width: 100%;
    max-width: 575px;
    display: flex;
    flex-direction: row;    
    justify-content: center;
    align-self: center;
}

.reachusattextdiv{
    justify-content: flex-end;
    text-align: end;
    min-width: 110px;
    max-width: 110px;
}

.reachusaticonsdivcontainer{
    display: flex;
    flex-direction: row;
}

.reachusiconsdiv{
	height: 30px;
    width: 30px;
    border-radius: 50%;
    border-style: solid;
    border-width: 0.8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    margin-top: 5px;
    margin-right: 10px;
}

.reachusicons{
	height: 85%;
    width: auto;
    object-fit:contain;
          resize: "both";
          align-self:center;
}


.reachustext{
	color: #222;
    font-size: 24px;
    text-transform: capitalize;
    margin-bottom: 2rem;
    letter-spacing: 0.2;
    font-family: "Graphik Regular","Arial",Sans-Serif;
    align-self: center;
	justify-content: flex-start;
	padding:1px 1.5px;
    text-align: left;
}