*{
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}



h1 {
  color: white;
  text-align: center;
}


.footercontactus{
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height:70%;
}

.footercontactustextdiv{
	display: flex;
	justify-content: center;
	width: 90%;
	min-height: 65px;
}	

.footercontactustext{
    color: #fff;
	font-size: 45px;
	margin: 0px;
}

.footercontactusformfields{
	display: flex;
	width: 90%;
	flex-direction: column;
	align-items: center;
}

.footercontactusformfield{
	border-radius: 7px;
	width: 90%;
	height: 13%;
	border: 1px;
	padding-left: 10px;
	font-size: 15px;
	min-height: 67px;
	color: #fff;
	margin-bottom: 10px;
}

.inputbox1{
	border-radius: 7px;
	height: 80%;
	width: 100%;
	border: 1px solid;
	padding-left: 10px;
	font-size: 19px;
	background-color: #fff;
}

.footercontactusformfieldquery{
	border-radius: 7px;
	width: 90%;
    min-height: 136px;
	border: 1px;
	padding-left: 10px;
	font-size: 15px;
}

.inputbox2{
	border-radius: 7px;
	width: 100%;
	border: 1px solid;
	padding-left: 10px;
	font-size: 19px;
	background-color: #fff;
}

.termsconditions{
    color: #fff;
	font-size: 20px;
	margin: 0px;
}

.footercontactustermsconditionsfield{
	border-radius: 7px;
	width: 90%;
	height: 9%;
	border: 1px;
	padding-left: 10px;
	font-size: 15px;
}

.footercontactussubmit{
	display: flex;
	justify-content: center;
    min-height: 65px;
	width: 90%;
	margin-bottom: 10px;
	margin-top:15px;
}

.submitinput{
	border-radius: 7px;
    min-height: 52px;
	width: 60%;
	border: 1px solid;
	padding-left: 10px;
	font-size: 19px;
	color: white;
	background-color: #a15123;
}




@media screen and (min-width: 1281px) and (max-width: 1460px) {
	.footercontactustext{
		font-size:22px;
	}

	.footercontactustextdiv{
		min-height:40px;
	}
  }
  
  @media screen and (min-width: 1025px) and (max-width: 1280px) {
	.footercontactustext{
		font-size:22px;
	}
	.footercontactustextdiv{
		min-height:35px;
	}
  }
  
  @media screen and (min-width: 768px) and (max-width: 1024px) {
	.footercontactustext{
		font-size:18px;
	}
	.footercontactustextdiv{
		min-height:30px;
	}

	.footercontactustext{
		font-size:18px;
	}

	.footercontactusformfield{
		min-height:35px;
		max-height:35px;
	}

	.submitinput{
		min-height: 35px;
	}

	.inputbox1{
		font-size: 20px;
	}

	.inputbox2{
		font-size: 20px;
	}

	

.footercontactussubmit {
	min-height: 35px;
  }
  }