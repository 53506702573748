*{
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}


@font-face {
    font-family: 'Graphik Light';
    src: local('Graphik-Light-Web'), url(../../Fonts/Graphik-Light-Web.woff2) format('woff2');
}

@font-face {
    font-family: 'Graphik Regular';
    src: local('Graphik-Regular-Web'), url(../../Fonts/Graphik-Regular-Web.woff2) format('woff2');
}

.progressive-nav-container {
    position: fixed;
    left: 0px;
    top: 80px;
    z-index: 100;
    display: flex;
    flex-direction: row;
    min-height: 75px;
    max-height: 75px;
    width: 100%;
    background-color: #f2f2f2;
    align-items: center;
    justify-content: flex-start;
}

.progressive-nav-item {
    margin-left:5%;
    display:flex;
    align-items:center;
    justify-content: center;
}

.progressive-nav-item p {
    font-size:16px;
    color:#111;
    font-family: "Graphik Regular","Arial",Sans-Serif;
}

.box1 {
        position:relative;
        opacity:1;
        width: 100%;
        min-height:70vh;
        max-height:120vh;
        margin-top: 155px;
}

.keyservcies-section {
        display: flex;
        height: 900px;
        width: 100%;
        background-color: white;
        align-items: center;
        justify-content: center;
        flex-direction: column; 
}

.keyservcies-section h2 {
    color: #222;
    font-size: 24px;
    text-transform: capitalize;
    margin-bottom: 2rem;
    letter-spacing: 0.2;
    font-family: "Graphik Light","Arial",Sans-Serif;
    align-self: center;
    margin-left: 10%;
    margin-right: 10%;
    text-align: center;
}

.keyservices-section-img {
    align-self: center;
    vertical-align: middle;
    margin-bottom: 3%;
    margin-top: 3%;
    height: 160px;
    width: 160px;
}


.keyservcies-img-ext-bottom {
    width: 0px;
    height: 0px;
    border-left-width: 50px;
    border-right-width: 50px;
    border-bottom-width: 25px;
    border-top-width: 25px;
    border-style: solid;
    background-color: transparent;
    border-left-color: transparent;
    border-top-color: #222;
    border-right-color: transparent;
    border-bottom-color: transparent;
    align-self: center;
    margin-top: 40px;
    z-index: 3;
}

.keyservices-container {
    display: flex; 
    height:800px;
    width: 100%;
    background-color: white;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
}

.keyservices-container:hover {
    background-image: var(--hover-image);
}

.div.keyservice-01:hover  .div.keyservice-02 {display:block;}

.keyservices-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 33.3%;
    margin-bottom: 1%;
    padding-left:10px;
    padding-right:10px;
}

.keyservice {
    flex: 33%;
    height: 100%;
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin:0.5%;
  }

.keyservice-ovrlay {
    height:100%;
    width:100%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding: 20px 35px;
    z-index: 2;
    -webkit-transition: all 300ms ease-out;
    -moz-transition: all 300ms ease-out;
    -o-transition: all 300ms ease-out;
    -ms-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background: rgba(255,255,255,.18);
    background: -moz-linear-gradient(top,rgba(255,255,255,.22) 0%,rgba(0,0,0,.47) 100%);
    background: -webkit-linear-gradient(top,rgba(255,255,255,.22) 0%,rgba(0,0,0,.47) 100%);
    background: linear-gradient(to bottom,rgba(255,255,255,.22) 0%,rgba(0,0,0,.47) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff',endColorstr='#000000',GradientType=0);
}

.keyservice-ovrlay p {
    font-size: 15px;
    color: #fff;
    font-weight: 400;
    height: 0;
    opacity: 0;
    font-family: "Graphik Light","Arial",Sans-Serif;
}

.keyservice-ovrlay:hover p {
    
    opacity: 1;
    -webkit-transition: all 300ms ease-out;
    -moz-transition: all 300ms ease-out;
    -o-transition: all 300ms ease-out;
    -ms-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
    transform: translateY(-20px);
    -webkit-transform: translateY(-20px);

}

@media only screen and (max-width: 991px) {
    .keyservice-ovrlay h2 {
    font-size: 14px;
}}
@media only screen and (max-width: 1199px) {
    .keyservice-ovrlay h2 {
    font-size: 15px;
}}
@media (max-width: 1499px) {
    .keyservice-ovrlay h2 {
    font-size: 18px;
}}
.keyservice-ovrlay h2{
    font-family: "Graphik Light","Arial",Sans-Serif;
    font-size: 18px;
    color: #fff;
    font-weight: 400;
    margin: 0;
    text-transform: capitalize;
    margin-bottom: 10px;
    -webkit-transition: all 300ms ease-out;
    -moz-transition: all 300ms ease-out;
    -o-transition: all 300ms ease-out;
    -ms-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
    transform: translateY(4px);
    -webkit-transform: translateY(4px);
}
.keyservice-ovrlay:hover h2{
    -webkit-transition: all 300ms ease-out;
    -moz-transition: all 300ms ease-out;
    -o-transition: all 300ms ease-out;
    -ms-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
    transform: translateY(-25px);
    -webkit-transform: translateY(-25px);
}

.devops-keyservices-banner  {
    background: linear-gradient(202.35deg,#d27f3b -24%,#05214f 100%);
    background: transparent linear-gradient(37deg,#c27b41 44%,#9f522c 66%,#c35725 100%) 0 0 no-repeat padding-box;
    background-color: #1f3c66;
    width: 100%;
	height:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 5;
	opacity:0.9;
}

.devops-keyservices-banner-2 {
    width: 90%;
	height:100%;
    display:flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    z-index: 6;
	opacity:1
}

.devops-keyservices-banner-img {
		background-image: url(../../Images/devops-keyservice-img-7.jpg);
		background-size: 100% 100%;
		resize: both;
		height: auto;
		width: 100%;
		opacity: 1;
}

.devops-keyservices-banner-h2 {
    color: #fff;
    font-size: 50px;
    text-transform: capitalize;
    font-family: "Graphik Regular","Arial",Sans-Serif;
    align-self: center;
    text-transform: none;
    font-weight: 500;
    letter-spacing: .84px;
    margin: 0 auto;
    margin-bottom: 1rem;
    line-height: 1.2!important;
    max-width: 82%;
    text-align: center;
}


.devops-keyservices-banner-box {
    width: 100%;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  opacity:1;
                  z-index:50;
                  position: absolute;
                  margin-top: 5%;
              
  }
  
    .devops-keyservices-banner-caption {
      height:auto;
      display:flex;
      align-items:center;
      justify-content: center;
      flex-direction: column;
      width:80%;
      z-index:500;
  }
  
    .devops-keyservices-banner-box h2 {
      color: orangered;
      font-size: 28px;
      margin-bottom: 1rem;
      letter-spacing: 0;
      font-family: "Graphik Light", "Arial", Sans-Serif;
      font-weight: 400;
      text-shadow: 1px 1px 3px #6e6e6e;
      z-index:500;
      text-align: center;
  }

  
  .devops-keyservices-banner-caption-h2 {
    color: orangered;
    font-size: 40px;
    margin-bottom: 1rem;
    letter-spacing: 0;
    font-family: "Graphik Light", "Arial", Sans-Serif;
    font-weight: 400;
    text-shadow: 1px 1px 3px #6e6e6e;
    z-index:500;
    text-align: center;
  }
  
  
  
  .devops-keyservices-banner-box h3 {
    color: orangered;
    font-size: 33px;
    margin-bottom: 1rem;
    letter-spacing: 0;
    font-family: "Graphik Light", "Arial", Sans-Serif;
    text-shadow: 1px 1px 3px #888787;
    z-index:500;
  }

  .end-banner {
    background-color: rgb(238, 237, 236);
    height: auto;
    width: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 5;
}

.end-banner h5 {
    color: #fff;
    font-size: 33px;
    text-transform: capitalize;
    font-family: "Graphik Regular","Arial",Sans-Serif;
    align-self: center;
    text-transform: none;
    font-weight: 500;
    letter-spacing: .84px;
    margin: 0 auto;
    margin-bottom: 1rem;
    line-height: 1.2!important;
    max-width: 85%;
    text-align: center;

}

.end-banner h3 {
    color: #fff;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: .66px;
    margin: 0 0 1.5rem;
    text-transform: uppercase;
    line-height: 1.8!important;
    text-transform: capitalize;
    font-family: "Graphik Regular","Arial",Sans-Serif;
    text-align: center;
}


.end-banner p {
    font-family: "Graphik Regular","Arial",Sans-Serif;
    font-size: 30px;
    text-align: center;
    color: #F2EFDE;
}

.footerBox {
    background-color: transparent;
    width:75%;
    border-radius: 30px;
}

.end-banner-btn {
    max-height:18%;
    width:30%;
    background-color: #fff;
    border-radius: 14px;
    display:flex;
    align-items: center;
    justify-content: center;
    flex:20;
}

.end-banner-btn h2 {
    color: #222;
    font-size: 33px;
    font-family: "Graphik Regular","Arial",Sans-Serif;
    align-self: center;
    text-transform: none;
    font-weight: 600;
    margin: 0 auto;
    line-height: 1.2!important;
    max-width: 85%;
    text-align: center;   
}

.devops-banner-container {
        width: 100%;
        min-height: 700px;
        max-height: 700px;
        display: flex;
        flex-direction: column;
}

@media screen and (min-width: 1281px) and (max-width: 1460px) {
    .progressive-nav-container {
        top: 65px;
        min-height: 50px;
        max-height: 50px;
    }

    .progressive-nav-item p {
        font-size: 14px;
    }
    .box1 {
        margin-top: 115px;
    }

    .keyservcies-section {
        min-height: 500px;
        max-height: 500px;
    }

    .keyservices-section-img {
        height: 130px;
        width: 130px;
    }

    .keyservcies-section h2 {
        font-size: 20px; 
    }

    .keyservcies-img-ext-bottom {
        border-left-width: 40px;
        border-right-width: 40px;
        border-bottom-width: 20px;
        border-top-width: 20px;
        margin-top: 25px;
    }

    .keyservices-container {
        height:700px;
    }

    .keyservice-ovrlay p {
        font-size: 14px;
    }
    .keyservice-ovrlay h2 {
        font-size: 16px;
    }
    .devops-banner-container {
        min-height:500px;
        max-height:500px;
    }

    .devops-keyservices-banner-box h2 {
        font-size: 24px;
    }

    .devops-keyservices-banner-box h3 {
        font-size: 26px;
    }

    .devops-keyservices-banner-caption-h2 {
        font-size: 36px;
    }
}
  
@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .progressive-nav-container {
        top: 60px;
        min-height: 45px;
        max-height: 45px;
    }

    .progressive-nav-item p {
        font-size: 12px;
    }
    .box1 {
        margin-top: 105px;
    }
    .keyservcies-section {
        min-height: 500px;
        max-height: 500px;
    }

    .keyservices-section-img {
        height: 120px;
        width: 120px;
    }

    .keyservcies-section h2 {
        font-size: 20px; 
    }

    .keyservcies-img-ext-bottom {
        border-left-width: 30px;
        border-right-width: 30px;
        border-bottom-width: 15px;
        border-top-width: 15px;
        margin-top: 20px;
    }

    .keyservices-container {
        height:600px;
    }
    .keyservice-ovrlay p {
        font-size: 12px;
    }
    .keyservice-ovrlay h2 {
        font-size: 14px;
    }

    .devops-banner-container {
        min-height:450px;
        max-height:450px;
    }

    .devops-keyservices-banner-box h2 {
        font-size: 22px;
    }

    .devops-keyservices-banner-box h3 {
        font-size: 24px;
    }

    .devops-keyservices-banner-caption-h2 {
        font-size: 33px;
    }
}
  
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .progressive-nav-container {
        top: 55px;
        min-height: 40px;
        max-height: 40px;
    }
    .progressive-nav-item p {
        font-size: 10px;
    }
    .box1 {
        margin-top: 95px;
    }
    .keyservcies-section {
        min-height: 450px;
        max-height: 450px;
    }

    .keyservices-section-img {
        height: 110px;
        width: 110px;
    }

    .keyservcies-section h2 {
        font-size: 18px; 
    }

    .keyservcies-img-ext-bottom {
        border-left-width: 30px;
        border-right-width: 30px;
        border-bottom-width: 15px;
        border-top-width: 15px;
        margin-top: 20px;
    }

    .keyservices-container {
        height:550px;
    }

    .keyservice-ovrlay p {
        font-size: 10px;
    }
    .keyservice-ovrlay h2 {
        font-size: 12px;
    }

    .devops-banner-container {
        min-height:400px;
        max-height:400px;
    }

    .devops-keyservices-banner-box h2 {
        font-size: 22px;
    }

    .devops-keyservices-banner-box h3 {
        font-size: 24px;
    }

    .devops-keyservices-banner-caption-h2 {
        font-size: 33px;
    }

    .end-banner-container {
        min-height:200px;
        max-height:200px;
    }

    .end-banner h5 {
        font-size:18px;
    }

    .end-banner h3 {
        font-size:14px;
    }

    .end-banner-btn h2 {
        font-size:18px;
    }
}




  

