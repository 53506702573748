
@font-face {
  font-family: 'Graphik Light';
  src: local('Graphik-Light-Web'), url(../Fonts/Graphik-Light-Web.woff2) format('woff2');
}
.container-slider {
    max-width: 1000px;
    height: 500px;
    margin: 100px auto 0;
    position: relative;
    /* overflow: hidden; (if 'hidden' arrows are not visible outside the container) */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }

.slider {
    width: 100%;
    height: 120vh;
    position: relative;
    overflow: hidden;
  }
  
  .slide {
    display:flex;
    align-self: 'flex-start';
    justify-content: 'flex-end';
    align-items: 'center';
    overflow: hidden;
    min-height:1000px;
    width:100%;
    flex-direction: column;
  }
  
  .current {
    opacity: 1;
    transform: translateX(0);
  }
  
  .content {
    position: absolute;
    top: 30rem;
    left: 8rem;
    opacity: 0;
    width: 50%;
    color: #F2EFDE;
    padding: 3rem;
    animation: slide-up 3s ease 0.5s;
    animation-fill-mode: forwards;
    visibility: hidden;
    font-size: 1.5vw;
  }

  .rightcontent {
    position: absolute;
    top: 25rem;
    left: 40%;
    opacity: 0;
    width: 50%;
    color: #FFFFCC;
    padding: 3rem;
    animation: display-slide-up 3s ease 0.5s;
    animation-fill-mode: forwards;
    visibility: hidden;
    font-size: 1.8vw;
  }

  
  
  @keyframes slide-up {
    0% {
      visibility: visible;
      top: 23rem;
    }
    100% {
      visibility: visible;
      top: 0rem;
    }
  }

  @keyframes display-slide-up {
    0% {
      visibility: visible;
      top: 23rem;
    }
    100% {
      visibility: visible;
      top: 0rem;
    }
  }

  @keyframes contact-slide-up {
    0% {
      visibility: visible;
      top: 60rem;
    }
    100% {
      visibility: visible;
      top: 20rem;
    }
  }

  @keyframes connect-slide-up {
    0% {
      visibility: visible;
      top: 60rem;
    }
    100% {
      visibility: visible;
      top: 24rem;
    }
  }
  
  @media screen and (max-width: 600px) {
    .content {
      width: 80%;
    }
  }
  
  .content > * {
    color: #fff;
    margin-bottom: 1rem;
  }
  
  .current .rightcontent {
    opacity: 1;
    transform: translateX(0);
    transition: all 0.5s ease;
  }

  .rightcontent > * {
    color: #fff;
    margin-bottom: 1rem;
  }
  
  .current .content {
    opacity: 1;
    transform: translateX(0);
    transition: all 0.5s ease;
  }
  
  .arrow {
    border: 2px solid white;
    background-color: transparent;
    color: #fff;
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    position: absolute;
    z-index: 999;
  }
  .arrow:hover {
    background-color: #fff;
    color: #777;
  }
  
  .next {
    top: 35%;
    right: 1.5rem;
  }
  .prev {
    top: 35%;
    left: 1.5rem;
  }
  
  hr {
    height: 2px;
    background: white;
    width: 50%;
  }

  .swiper-slide-active .banner-box,
.swiper-slide-duplicate-active .banner-box {
  width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 42%;
                animation: text-slide-up 1s ease 1s; 
                
    animation-fill-mode: forwards;
                opacity:0;
}

  @keyframes text-slide-up {
    0% {
      visibility: visible;
      top: 50%;
      opacity:1;
    }
    100% {
      visibility: visible;
      top: 42%;
      opacity:1;
    }
  }

.slide-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.banner-caption {
    height:auto;
    display:flex;
    align-items:center;
    justify-content: center;
    flex-direction: column;
    width:80%;
}

.banner-caption h2 {
    color: #fff;
    font-size: 65px;
    letter-spacing: 0;
    font-family: "Graphik Light", "Arial", Sans-Serif;
    font-weight: 400;
    text-shadow: 1px 1px 3px #4a4a4a;
}

.banner-caption h4 {
  color: #fff;
  letter-spacing: 0;
  font-family: "Graphik Light","Arial Black",Sans-Serif !important;
    font-size: 33px;
    font-weight: normal !important;
    line-height: 1;
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-shadow: 1px 1px 3px #4a4a4a;
}

.banner-btn {
  height:5vh;
  width:auto;
  border-radius: 14px;
  border: 2px solid #fff;
  background-color: transparent;
  display:flex;
  align-items: center;
  justify-content: center;
  transition: background-color 1s ease-in-out;
}


.banner-btn:hover {
  -webkit-transform:scale(1.15); /* Safari and Chrome */
    -moz-transform:scale(1.15); /* Firefox */
    -ms-transform:scale(1.15); /* IE 9 */
    -o-transform:scale(1.15); /* Opera */
     transform:scale(1.15);
     background-color: white;
}

.banner-btn h2 {
  color: #fff;
  font-size: 30px;
  letter-spacing: 0;
  font-family: "Graphik Light", "Arial", Sans-Serif;
  font-weight: 400;
  text-shadow: 1px 1px 3px #4a4a4a;
  padding: 1em 2em 1em;
  transition: color 1s ease-in-out;
}

.banner-btn h2:hover {
  color: #111;
}

@media screen and (min-width: 1281px) and (max-width: 1460px) {
  .banner-caption h2 {
    font-size: 54px;
  }

  .banner-caption h4 {
    font-size: 28px;
  }

  .banner-btn h2 {
    font-size: 26px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
  .banner-caption h2 {
    font-size: 46px;
  }

  .banner-caption h4 {
    font-size: 23px;
  }

  .banner-btn h2 {
    font-size: 22px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .banner-caption h2 {
    font-size: 38px;
  }
  .banner-caption h4 {
    font-size: 18px;
  }

  .banner-btn h2 {
    font-size: 18px;
  }
}
