.dropdown-container-1{
  position: absolute;
  top: 78px;
  left: 0px;
  width: 100%;
}

.services-submenu {
    position: absolute;
    left:0px;
    text-align: start;
    width: 100%;
    height:auto;
    padding-left: 0.5rem;
    background-color: #fff;
    opacity:1;
    transition: height var(--speed) ease;
    z-index:1000
  }
  
  .services-submenu li {
    background: #fff;
    cursor: pointer;
    list-style-type:square;
    margin-left:8%;
  }
  
  .services-submenu li a:hover {
    background: #fff;
    opacity:0.8
  }
  
  .services-submenu.clicked {
    display: none;
  }
  
  .submenu-item {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    font-size-adjust: 4;
    color: #000;
  }

  .submenu-item-box {
    display:flex;
    width:100%;
    align-items: flex-start;
    background: #fff;
    cursor: pointer;
    list-style-type:square;
    min-height: 30px;
    max-height: 30px;
  }

  #li:before {
    cursor: pointer;
    list-style-type:square;
  }

  .submenu-heading {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    font-size-adjust: 7;
    color: #000;
  }

  .submenuHeader {
    font-size: 18px;
    color: #111;
    font-weight: bold;
    font-family: "Graphik Regular","Arial",Sans-Serif;
    border-bottom: 1px solid #000;
    letter-spacing: .84px;
    margin-bottom: 10px;
  }

  .submenuItem  {
    display: block;
    font-size: 1rem;
    color: #222;
    padding-top: 15;
    font-family: "Graphik Regular","Arial",Sans-Serif;
  }

  .dropdown-row {
    display:flex;
    flex-direction: row;
    margin-left: 2%;
    width: 100%;
    margin-bottom: 2%;
  }

  .dropdown-row-box {
    display:flex;
    flex-direction: column;
    margin-left: 2%;
    width: 100%;
  }

  
  @media screen and (min-width: 1281px) and (max-width: 1460px) {
    .dropdown-container-1 {
      top: 63px;
    }

    .submenuHeader {
      font-size: 16px;
    }

    .submenu-item-box {
      min-height: 26px;
      max-height: 26px;
    }
  }
  
  @media screen and (min-width: 1025px) and (max-width: 1280px) {
    .dropdown-container-1 {
      top: 58px;
    }

    .submenuHeader {
      font-size: 14px;
    }

    .submenu-item-box {
      min-height: 22px;
      max-height: 22px;
    }
  }
  
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .dropdown-container-1 {
      top: 53px;
    }

    .submenuHeader {
      font-size: 12px;
    }

    .submenu-item-box {
      min-height: 20px;
      max-height: 20px;
    }
  }
